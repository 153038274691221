import React from 'react';

const css = `
  .st0{enable-background: new;}
  .st1{fill:none;stroke:#ffffff;stroke-width:6;stroke-miterlimit:10;}
`;

export const Outline = React.forwardRef(({ img }, ref) => {
  return (
    <svg
      id="outline-svg"
      x="0px"
      y="0px"
      viewBox="0 0 173.09 107.3"
      style={{ enableBackground: 'new 0 0 173.09 107.3' }}>
      <style type="text/css">{css}</style>
      <defs>
        <mask ref={ref} id="theMask">
          <path
            data-start="0"
            data-duration="10"
            className="st1"
            d="M30.07,16.15c-0.75,1.5-1.76,2.46-3.04,2.88c-1.28,0.43-2.54,0.43-3.76,0c-1.23-0.42-2.27-1.22-3.12-2.4
          c-0.85-1.17-1.17-2.67-0.96-4.48c0.32-2.24,1.12-3.89,2.4-4.96c1.28-1.06,2.85-1.73,4.72-2c1.87-0.26,3.89-0.26,6.08,0
          c2.19,0.27,4.24,0.62,6.16,1.04c2.45,0.54,4.96,1.12,7.52,1.76c2.48,0.62,4.92,1.04,7.3,1.26l0.3,0.03
          c0.88,0.11,5.07,0.25,5.99,0.15c0.96-0.11,1.81-0.37,2.56-0.8c1.49-0.75,2.21-1.76,2.16-3.04c-0.06-1.28-0.99-2.03-2.8-2.24
          C58,3.04,55.04,5.69,53.64,9.15l-0.12,0.32c-1.47,4-2.77,8.34-3.93,13c-1.17,4.75-2.29,9.58-3.36,14.48
          c-1.07,4.91-2.08,9.79-3.04,14.64c-0.96,4.85-2.13,10.32-3.2,14.8l-0.11,0.47c-0.51,2.12-1.08,4.47-1.73,7.05
          c-0.69,2.77-1.49,5.57-2.4,8.4c-0.91,2.83-1.98,5.54-3.2,8.16c-1.23,2.61-2.64,4.93-4.24,6.96c-2.13,2.77-4.64,4.69-7.52,5.76
          c-2.88,1.07-5.63,1.36-8.24,0.88c-2.61-0.48-4.83-1.68-6.64-3.6c-1.81-1.92-2.77-4.48-2.88-7.68c-0.21-4.91,0.91-8.96,3.36-12.16
          c2.46-3.2,5.52-5.79,9.2-7.76c3.68-1.98,7.71-3.44,12.08-4.4c3.93-0.86,7.65-1.51,11.16-1.94l2.19-0.14l2.82-0.32
          c2.88-0.32,5.65-0.72,8.32-1.2c2.67-0.48,5.17-1.2,7.52-2.16c2.35-0.96,4.53-2.27,6.56-3.92c2.02-1.65,3.89-3.76,5.6-6.32"
          />
          <path
            data-start="30"
            data-duration="10"
            className="st1"
            d="M93.03,51.97c0.06,3.69-0.43,7.83-1.08,10.27l-0.07,0.24c-0.74,2.6-2.03,4.79-3.89,6.57
          c-1.91,1.85-4.35,2.67-7.31,2.47c-1.19-0.06-2.32-0.35-3.41-0.84c-1.09-0.49-2.06-1.14-2.92-1.93c-0.86-0.79-1.53-1.71-2.03-2.77
          c-0.49-1.05-0.74-2.21-0.74-3.46c0-1.58,0.31-2.98,0.94-4.2c0.63-1.22,1.43-2.31,2.42-3.26c0.99-0.95,2.11-1.81,3.36-2.57
          c0,0,2.73-1.66,3.47-2.11l0.39-0.24l2.62-1.61c0.76-0.49,1.45-1.05,2.08-1.68c0.63-0.63,1.15-1.32,1.58-2.08
          c0.43-0.76,0.64-1.63,0.64-2.62c0.06-1.52-0.18-2.72-0.74-3.61c-0.56-0.89-1.24-1.43-2.03-1.63c-0.79-0.2-1.61-0.05-2.47,0.44
          c-0.86,0.49-2.07,1.27-2.67,2.52l-0.02,0.05c-0.79,1.71-1.03,3.43-0.77,5.24c0.15,1.06,0.47,2.76,1.45,4.81l0.26,0.47l0.62,0.99
          c1.11,1.91,2.46,3.74,4.03,5.48c1.57,1.75,3.57,3.77,5.21,5.29l0.28,0.25c1.15,1.02,2.29,1.96,3.42,2.81
          c1.22,0.92,2.39,1.78,3.51,2.57"
          />
          <path
            data-start="50"
            data-duration="10"
            className="st1"
            d="M89.02,77.19c-6.3,1.16-10.81,3.35-13.51,6.56c-2.7,3.22-4.02,6.4-3.96,9.55c0.07,3.15,1.35,5.85,3.86,8.11
          c2.51,2.25,5.82,2.99,9.94,2.22c3.21-0.65,6.18-2.29,8.88-4.92c2.7-2.64,5.12-5.86,7.24-9.65c2.12-3.8,3.99-7.98,5.6-12.55
          c1.61-4.57,2.99-9.1,4.15-13.61c1.16-4.5,2.12-8.78,2.9-12.84c0.74-3.91,1.31-7.24,1.69-10.01l0.07-0.56l0.74-6.12
          c0.13-1.41,0.29-3.18,0.48-5.31c0.19-2.12,0.29-4.28,0.29-6.47c0-2.19-0.1-4.31-0.29-6.37c-0.19-2.06-0.68-3.67-1.45-4.83
          c-1.16-1.93-2.51-2.48-4.05-1.64c-1.54,0.84-2.64,2.03-3.28,3.57c-0.77,1.93-1.06,4.09-0.87,6.47c0.19,2.38,0.71,4.76,1.54,7.14
          c0.84,2.38,1.8,4.67,2.9,6.85c1.09,2.19,2.09,4.05,2.99,5.6l0.79,1.11l0.39,0.58c4.32,6.7,9.17,13.08,14.55,19.16
          c5.47,6.18,10.2,12.68,14.19,19.5c-0.26,1.67-3.54,3.7-3.79,6.21c-0.26,2.51,0.88,4.92,1.91,6.46c1.03,1.41,4.52,1.64,5.93,1.33
          c1.41-0.32,0.34,0.14,0.98-1.66c0.39-1.16-0.41-2.2-0.67-3.55c-0.26-1.35-0.27-2.47-0.85-3.82c-0.58-1.35-2.69-3.68-3.52-4.96
          l0.97-5.6c0.9-4.89,2.15-10.58,3.76-17.08c1.61-6.5,3.48-13.03,5.6-19.59c2.12-6.56,4.44-12.84,6.95-18.82
          c2.51-5.98,5.83-11.97,8.53-15.96"
          />
        </mask>
      </defs>
      <g mask="url(#theMask)" className="st0">
        <image xlinkHref={img} x="0" y="0" width="100%" height="100%" />
      </g>
    </svg>
  );
});
